<template>
  <div class="bg-beige-100 flex min-h-screen flex-col">
    <div class="w-full">
      <div class="fixed z-50 mx-auto w-full">
        <TnHeader />
      </div>

      <div class="bg-blue-400 pb-8 pt-28">
        <div class="pt-8">
          <div class="flex items-center justify-center">
            <TnHeading>PRODUCTS</TnHeading>
          </div>
        </div>
      </div>
      <div class="h-[12px] bg-[url('/assets/pink-stripped-pattern.svg')]" />
      <div class="relative mx-auto max-w-7xl px-0 pb-6">
        <slot />
      </div>
    </div>

    <TnFooter class="mt-auto" />
  </div>
</template>
